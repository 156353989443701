const breakpoint = window.matchMedia('(min-width:1187px)');
let buttons;
let dropdownList;

const handleFocusChange = (evt) => {
  const button = evt.currentTarget.previousElementSibling;

  return evt.type === 'mouseover' || evt.type === 'focus' ?
    button.classList.add('is-active') :
    button.classList.remove('is-active');
};

const addEvents = () => {
  dropdownList.forEach((list) => {
    list.addEventListener('focus', handleFocusChange, true);
    list.addEventListener('blur', handleFocusChange, true);
    list.addEventListener('mouseover', handleFocusChange);
    list.addEventListener('mouseout', handleFocusChange);
  });

  buttons.forEach((button) => {
    button.addEventListener('focus', handleButtonChange, true);
    button.addEventListener('blur', handleButtonChange, true);
    button.addEventListener('mouseover', handleButtonChange);
    button.addEventListener('mouseout', handleButtonChange);
  });
};

const removeEvents = () => {
  dropdownList.forEach((item) => {
    item.removeEventListener('focus', handleFocusChange, true);
    item.removeEventListener('blur', handleFocusChange, true);
    item.removeEventListener('mouseover', handleFocusChange);
    item.removeEventListener('mouseout', handleFocusChange);
  });

  buttons.forEach((button) => {
    button.removeEventListener('focus', handleButtonChange, true);
    button.removeEventListener('blur', handleButtonChange, true);
    button.removeEventListener('mouseover', handleButtonChange);
    button.removeEventListener('mouseout', handleButtonChange);
  });
};

const handleButtonChange = (evt) => {
  evt.preventDefault();

  const currentList = evt.target.nextElementSibling;
  if (!currentList) {
    return;
  }

  if (evt.type === 'click' || evt.type === 'mouseover' || evt.type === 'focus') {
    evt.target.classList.add('is-active');
    currentList.classList.add('is-active');
  } else {
    evt.target.classList.remove('is-active');
    currentList.classList.remove('is-active');
  }
};

const removeButtonsEvents = () => {
  buttons.forEach((item) => {
    item.removeEventListener('click', handleButtonChange);
  });
};

const closeAllDropdown = () => {
  const removeActiveClass = (item) => item.classList.remove('is-active');

  buttons = document.querySelectorAll('[data-btn="dropdown"]');
  dropdownList = document.querySelectorAll('[data-dropdown-list]');

  buttons.forEach(removeActiveClass);
  dropdownList.forEach(removeActiveClass);
};

const changeBreakpoint = (evt) => {
  const matches = evt ? evt.matches : breakpoint.matches;
  buttons = document.querySelectorAll('[data-btn="dropdown"]');
  dropdownList = document.querySelectorAll('[data-dropdown-list]');

  if (matches && dropdownList) {
    addEvents();
    removeButtonsEvents();

  } else {
    removeEvents();
  }
};

const initDropdownMenu = () => {
  breakpoint.addListener(changeBreakpoint);
  changeBreakpoint();
};

export {initDropdownMenu, closeAllDropdown};
