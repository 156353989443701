const initPrint = () => {
  const printBtn = document.querySelector('[data-print]');

  if (!printBtn) {
    return;
  }

  printBtn.addEventListener('click', () => {
    window.print();
  });
};

export {initPrint};
