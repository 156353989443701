const mainNav = document.querySelector('.main-nav');
const searchBlock = document.querySelector('[data-header-search]');
const input = document.querySelector('input[type=search]');
const breakpoint = window.matchMedia('(max-width: 1187px)');

const breakpointChecker = () => {
  if (!breakpoint.matches) {
    searchBlock.classList.remove('is-active');
  } else {
    if (searchBlock.classList.contains('is-active')) {
      mainNav.removeEventListener('click', onDocumentClick);
    } else {
      input.addEventListener('click', () => {
        searchBlock.classList.add('is-active');
      });
      mainNav.addEventListener('click', onDocumentClick);
    }
  }
};

const onDocumentClick = (e) => {
  let target = e.target;

  if (!target.closest('searchBlock')) {
    e.preventDefault();
    searchBlock.classList.remove('is-active');
  }
};

const initSearchHeader = () => {
  if (!input) {
    return;
  }

  breakpointChecker();
  breakpoint.addListener(breakpointChecker);
};

export {initSearchHeader};
