import {iosVhFix} from './utils/ios-vh-fix';
import {initModals} from './modules/modals/init-modals';
import {Form} from './modules/form-validate/form';
import {CustomSelect} from './modules/select/custom-select';
import {uploadFile, uploadImageDrop} from './modules/input-file/init-upload';
import {initBurgerAction} from './modules/init-burger-action';
import {initDropdownMenu} from './modules/init-dropdown-menu';
import {initSearchHeader} from './modules/init-search-header';
// import {initPriorityNav} from './modules/init-priority-nav';
import {initDynamicAdaptive} from './utils/init-dynamic-adaptive';
import {setHeaderHeight} from './modules/set-header-height';
import {initPrint} from './modules/init-print';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();

  // Modules
  initBurgerAction();
  initDropdownMenu();
  // initPriorityNav();

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    // initSearchHeader();
    initDynamicAdaptive();
    setHeaderHeight();
    initPrint();
  });
});
