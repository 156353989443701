import {ScrollLock} from '../utils/scroll-lock';
import {closeAllDropdown} from './init-dropdown-menu';

const burgerBtn = document.querySelector('[data-btn="mobile-toggle"]');
const navBlock = document.querySelector('.main-nav__wrapper');
const headerBlock = document.querySelector('.header');
const searchBlock = document.querySelector('[data-header-search]');
const breakpointLg = window.matchMedia('(min-width:1187px)');
const scrollLock = new ScrollLock();

const onEscPress = (evt) => {
  const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

  if (isEscKey && navBlock.classList.contains('is-active')) {
    evt.preventDefault();
    closeMenu();
    window.removeEventListener('keydown', onEscPress);
  }
};

const openMenu = () => {
  burgerBtn.ariaPressed = 'true';
  navBlock.classList.add('is-active');
  scrollLock.disableScrolling();
  headerBlock.classList.add('is-active');
  burgerBtn.classList.add('is-active', 'no-pointer');
  setTimeout(() => {
    burgerBtn.classList.remove('no-pointer');
  }, 1000);
  window.addEventListener('keydown', onEscPress);
};

const closeMenu = () => {
  burgerBtn.ariaPressed = 'false';
  headerBlock.classList.remove('is-active');
  navBlock.classList.remove('is-active');

  closeAllDropdown();

  scrollLock.enableScrolling();
  burgerBtn.classList.remove('is-active');
  burgerBtn.classList.add('no-pointer');

  if (searchBlock.classList.contains('is-active')) {
    searchBlock.classList.remove('is-active');
  }

  setTimeout(() => {
    burgerBtn.classList.remove('no-pointer');
  }, 1200);
};
const closeMenuOnResize = () => {
  if (navBlock.classList.contains('is-active')) {
    closeMenu();
  }
};

const initBurgerAction = () => {
  if (burgerBtn) {
    if (navBlock.classList.contains('is-active')) {
      closeMenu();
    }

    burgerBtn.addEventListener('click', () => {
      if (burgerBtn.ariaPressed === 'true') {
        closeMenu();
      } else {
        openMenu();
      }
    });
  }
};

export {initBurgerAction, closeMenu};
